import * as React from 'react'


import Layout from "../../components/panel/layout"
import Seo from "../../components/seo"
import {sg_center_text} from "../../components/index.module.css";

// Enable pusher logging - don't include this in production

const PortablePage = () => {

    return(
        <Layout>

            <Seo title="پرتابل پشتیبان گروپ" />
            <div>
                <div className={sg_center_text}>به پرتابل پشتیبان گروپ خوش امدید</div>
            </div>
        </Layout>
    )
}

export default PortablePage
